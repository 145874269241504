import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import config from "../../config.json"
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Grid,
  Typography,
  Link,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Alert,
  CircularProgress,
} from "@mui/material"
import { jwtDecode } from "jwt-decode"
import setHeaders from "../Feed/EditGtfs/utils/setHeaders"
import { Bubble } from "../Background/Bubble"
import { NavLink } from "react-router-dom"

const CancelSubscription = ({ set_premium, set_unsubscribe }) => {
  const { user_email } = jwtDecode(localStorage.token)
  const [user_input, set_user_input] = useState("")
  const [alert, set_alert] = useState(null)
  const [loading, set_loading] = useState(false)

  const cancel_subscription = async () => {
    try {
      set_loading(true)
      if (user_email !== user_input) {
        return set_alert({
          severity: "error",
          message: "Input provided does not match the email.",
        })
      }
      await axios.delete(config.api_url + "/subscribe/")
      const { data } = await axios.get(config.api_url + `/login/refresh`)
      setHeaders(data.token)
      localStorage.setItem("token", data.token)
      set_premium(false)
      set_alert({
        severity: "success",
        message: "Subscription cancelled successfully.",
      })
    } catch (error) {
      set_alert({ severity: "error", message: "An error occurred." })
      console.log(error)
    } finally {
      set_loading(false)
    }
  }

  return (
    <Bubble>
      <DialogContent style={{ maxWidth: 600 }}>
        <DialogTitle color="red" id="form-dialog-title">
          Unsubscribe (CAUTION)
        </DialogTitle>
        <DialogContentText color="red">
          Are you sure you want to unsubscribe from the platform? All of your
          data will be removed. This includes all feeds, versions and work in
          progress. It is highly recommended that you export all of your data
          before proceeding.
        </DialogContentText>
        <Typography marginTop={2}>
          User Email: <b>{user_email}</b>
        </Typography>
        <TextField
          margin="dense"
          fullWidth
          variant="standard"
          label="Enter the user email address to continue."
          value={user_input}
          onChange={(e) => {
            set_user_input(e.target.value)
          }}
        />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              set_unsubscribe(false)
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            variant="outlined"
            onClick={cancel_subscription}
            color="error"
          >
            Delete Feed
          </Button>
        </DialogActions>
        {alert ? (
          <Alert severity={alert.severity}>{alert.message}</Alert>
        ) : null}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : null}
      </DialogContent>
    </Bubble>
  )
}

const Subscribe = () => {
  const BuyButton = () => {
    const [paymet_data, set_paymet_data] = useState(null)
    useEffect(() => {
      axios.get(config.api_url + "/subscribe/data").then(({ data }) => {
        set_paymet_data(data)
      })
    }, [])

    if (!paymet_data) {
      return null
    }

    return (
      <form action={paymet_data.pfHost} method={paymet_data.method}>
        {Object.keys(paymet_data.details).map((key) => {
          const value = paymet_data.details[key]
          return (
            <input key={key} name={key} type="hidden" value={value.trim()} />
          )
        })}

        <Box display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" type="submit">
            Subscribe Now
          </Button>
        </Box>
      </form>
    )
  }

  return (
    <Bubble>
      <CardHeader
        avatar={<Avatar variant="square" src={"/images/lock.png"} />}
        title={"Subscriptions"}
        titleTypographyProps={{ variant: "h5" }}
      />
      <Typography margin={2}>
        Current status: <b>Not Subscribed</b>
      </Typography>
      <Typography margin={2}>
        Are you ready to subscribe and unlock all functionality on the platform?
        Click the button below to be routed to our payment processor. We make
        use of{" "}
        <Link target="_blank" href="https://payfast.io/">
          payfast
        </Link>
        .
      </Typography>
      <Typography margin={2}>
        Do not worry about getting locked in. We will make a link available to
        easily unsubscribe from the platform.
      </Typography>
      <BuyButton />
    </Bubble>
  )
}

const UnSubscribe = ({ set_unsubscribe }) => {
  return (
    <Bubble>
      <CardHeader
        avatar={<Avatar variant="square" src={"/images/crown.png"} />}
        title={"Subscriptions"}
        titleTypographyProps={{ variant: "h5" }}
      />
      <Typography margin={2}>
        Current status: <b>Subscribed</b>
      </Typography>

      <Fragment>
        <Typography margin={2}>
          You are a premium user of the platform. All functionality has been
          unlocked.
        </Typography>
        <DialogActions>
          <Button
            variant="outlined"
            to={`/home`}
            component={NavLink}
            style={{ marginRight: 8 }}
          >
            Home
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              set_unsubscribe(true)
            }}
          >
            Unsubscribe
          </Button>
        </DialogActions>
      </Fragment>
    </Bubble>
  )
}

const ManageSubscription = () => {
  const [premium, set_premium] = useState(jwtDecode(localStorage.token).premium)
  const [unsubscribe, set_unsubscribe] = useState(false)

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        {premium ? (
          <UnSubscribe set_unsubscribe={set_unsubscribe} />
        ) : (
          <Subscribe />
        )}
      </Grid>
      <Grid item>
        {unsubscribe ? (
          <CancelSubscription
            set_premium={set_premium}
            set_unsubscribe={set_unsubscribe}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}

export default ManageSubscription
