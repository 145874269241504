import React, { Fragment } from "react"
import Feeds from "./Feeds"
import { Grid, Link, Typography } from "@mui/material"
import { Bubble } from "../Background/Bubble"
import { NavLink } from "react-router-dom"
import { jwtDecode } from "jwt-decode"

const GtfsGuide = () => {
  return (
    <Fragment>
      <Typography variant="h5" padding={2}>
        GTFS Specification
      </Typography>
      <Typography margin={2}>
        Need some more information on the GTFS specification? Find it{" "}
        <Link target="_blank" href="https://developers.google.com/transit/gtfs">
          here
        </Link>
        .
      </Typography>
    </Fragment>
  )
}

const Subscriptions = () => {
  return (
    <Fragment>
      <Typography variant="h5" padding={2}>
        Subscriptions
      </Typography>
      <Typography margin={2}>
        Current status:{" "}
        <b>
          {jwtDecode(localStorage.token).premium
            ? "Subscribed"
            : "Not Subscribed"}
        </b>
      </Typography>
      <Typography margin={2}>
        Want to manage your subscription to the platform? Go to{" "}
        <Link component={NavLink} to="/subscribe">
          subscriptions
        </Link>
        .
      </Typography>
    </Fragment>
  )
}

const Home = () => {
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        <Bubble>
          <GtfsGuide />
        </Bubble>
      </Grid>
      <Grid item>
        <Bubble>
          <Feeds />
        </Bubble>
      </Grid>
      <Grid item>
        <Bubble>
          <Subscriptions />
        </Bubble>
      </Grid>
    </Grid>
  )
}

export default Home
