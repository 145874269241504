import * as React from "react"
import Button from "@mui/material/Button"
import LockIcon from "@mui/icons-material/Lock"
import { jwtDecode } from "jwt-decode"

const LockedButton = ({ text, onClick, fullWidth }) => {
  const user = jwtDecode(localStorage.token)
  const { premium } = user

  return (
    <Button
      color="primary"
      fullWidth={fullWidth}
      variant="outlined"
      onClick={onClick}
      disabled={!premium}
      startIcon={premium ? null : <LockIcon />}
    >
      {`${text} ${premium ? "" : "(Locked)"}`}
    </Button>
  )
}

export default LockedButton
