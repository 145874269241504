import "./App.css"
import React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { styled } from "@mui/material/styles"
import Home from "./components//Home/Home"
import NavBar from "./components/NavBar/NavBar"
import LandingPage from "./components/Landing"
import setHeaders from "./components/Feed/EditGtfs/utils/setHeaders"
import FeedOverview from "./components/Feed/FeedOverview/FeedOverview"
import ViewGtfs from "./components/Feed/ViewGtfs/ViewGtfs"
import EditGtfs from "./components/Feed/EditGtfs/EditGtfs"
import Background from "./components/Background/Background"
import Login from "./components/UserManagement/Login"
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess"
import Subscribe from "./components/Payments/ManageSubscription"

const theme = createTheme({
  palette: {
    primary: {
      main: "#01366A",
    },
    secondary: {
      main: "#fafafa",
    },
  },
})

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar)

if (localStorage.token) {
  setHeaders(localStorage.token)
}

function App() {
  const PrivateRoutes = () => {
    return localStorage.token ? <Outlet /> : <Navigate to="/login" />
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Offset />
      <Background>
        <Routes>
          <Route path={"/welcome"} element={<LandingPage />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/payment_success"} element={<PaymentSuccess />} />
          <Route element={<PrivateRoutes />}>
            <Route path={"/home"} element={<Home />} />
            <Route path={"/feed/:feed_pk"} element={<FeedOverview />} />
            <Route
              path={"/view/:feed_pk/:source/:pk/*"}
              element={<ViewGtfs />}
            />
            <Route
              path={"/edit/:feed_pk/:source/:pk/*"}
              element={<EditGtfs />}
            />
            <Route path={"/subscribe"} element={<Subscribe />} />
          </Route>
          <Route path="*" element={<Navigate to="/welcome" />} />
        </Routes>
      </Background>
    </ThemeProvider>
  )
}

export default App
