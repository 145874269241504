import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import GetAppIcon from "@mui/icons-material/GetApp"
import EditIcon from "@mui/icons-material/Edit"
import { Fragment, useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import config from "../../../config.json"
import LockedButton from "../../LockedButton/LockedButton"

const ConfirmDelete = (props) => {
  const [is_loading, set_is_loading] = useState(false)
  const { delete_pk, set_delete_pk, set_versions } = props

  const delete_version = async () => {
    try {
      const response = await axios.delete(
        config.api_url + `/version/${delete_pk}`
      )
      set_versions((versions) => {
        const updated_versions = versions.filter((version) => {
          return version.gtfs_version_pk !== response.data.gtfs_version_pk
        })
        return updated_versions
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    set_delete_pk(null)
  }

  return (
    <Dialog
      open={delete_pk !== null}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle color="red" id="form-dialog-title">
        Delete Version
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="red">
          Are you sure you want to delete this version? You will not be able to
          recover it.
        </DialogContentText>
        {is_loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={async () => {
            set_is_loading(true)
            await delete_version()
            set_is_loading(false)
            handleClose()
          }}
          color="error"
        >
          Delete Version
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Versions = ({ versions, set_versions, feed_pk }) => {
  const [delete_pk, set_delete_pk] = useState(null)
  useEffect(() => {
    axios.get(config.api_url + `/feed/${feed_pk}/versions`).then(({ data }) => {
      set_versions(data)
    })
  }, [set_versions, feed_pk])

  const download_version = (version_pk) => {
    axios({
      url: config.api_url + `/version/download/${version_pk}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "GTFS.zip")
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }
  const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Fragment>
      <Typography margin={2} variant="h5">
        Versions
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Created</b>
              </TableCell>
              <TableCell align="center">
                <b>View</b>
              </TableCell>
              <TableCell align="center">
                <b>Edit</b>
              </TableCell>
              <TableCell align="center">
                <b>Download</b>
              </TableCell>
              <TableCell align="center">
                <b>Delete</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Alert severity="info">No Versions.</Alert>
                </TableCell>
              </TableRow>
            ) : (
              versions.map((version) => {
                const { gtfs_version_pk, log_time } = version
                const created_date = new Date(log_time)
                const date = created_date.toLocaleDateString("en-GB", {
                  timeZone: user_timezone,
                })

                const time = created_date.toLocaleTimeString("en-GB", {
                  timeZone: user_timezone,
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })

                return (
                  <TableRow key={gtfs_version_pk}>
                    <TableCell component="th" scope="row" align="center">
                      {date} - {time}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        to={`/view/${feed_pk}/version/${gtfs_version_pk}/agency`}
                        component={Link}
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        to={`/edit/${feed_pk}/version/${gtfs_version_pk}/agency`}
                        component={Link}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          download_version(gtfs_version_pk)
                        }}
                      >
                        <GetAppIcon />
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          set_delete_pk(gtfs_version_pk)
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container style={{ marginTop: 5 }} spacing={1}>
        <Grid item md={6} xs={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            style={{ float: "right" }}
            component={Link}
            to={`/edit/${feed_pk}/new/-/agency`}
          >
            Blank Version
          </Button>
        </Grid>
        <Grid item md={6} xs={12}>
          <VersionUpload set_versions={set_versions} feed_pk={feed_pk} />
        </Grid>
      </Grid>

      <ConfirmDelete
        delete_pk={delete_pk}
        set_delete_pk={set_delete_pk}
        set_versions={set_versions}
      />
    </Fragment>
  )
}

const VersionUpload = ({ set_versions, feed_pk }) => {
  const [alerts, set_alerts] = useState([])
  const [is_file_loading, set_is_file_loading] = useState(false)
  const [file, setFile] = useState(null)
  const [open, set_open] = useState(false)

  const handle_close = () => {
    set_open(false)
  }

  const on_file_selected = (event) => {
    setFile(event.target.files[0])
  }

  const upload_file = async () => {
    set_alerts([])
    if (file === null) {
      set_alerts(["No file selected."])
    } else {
      try {
        const postForm = new FormData()
        postForm.append("gtfs_file", file)
        postForm.append("feed_pk", feed_pk)

        set_is_file_loading(true)
        const { data } = await axios.post(
          config.api_url + "/version/upload",
          postForm
        )

        set_versions((versions) => {
          let updated_versions = [...versions]
          updated_versions.unshift(data)
          return updated_versions
        })
        set_alerts([
          {
            severity: "success",
            text: "Version uploaded successfully.",
          },
        ])
      } catch (error) {
        console.log(error)
        set_alerts(
          error.response.data.map((text) => {
            return {
              severity: "error",
              text,
            }
          })
        )
      } finally {
        set_is_file_loading(false)
      }
    }
  }

  return (
    <Fragment>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          set_open(true)
        }}
      >
        Upload Version
      </Button>
      <Dialog open={open} onClose={handle_close}>
        <DialogContent>
          <Typography id="form-dialog-title" variant="h5">
            Upload a version from a GTFS file
          </Typography>
          <DialogContentText>
            Already have a GTFS feed that you would like to use as a starting
            point? Select a GTFS file from local storage and click 'Upload Feed'
            to add a new dataset to the system.
          </DialogContentText>
          <FormControl fullWidth>
            <Input type="file" onChange={on_file_selected} />
          </FormControl>

          <div style={{ marginTop: "10px" }}>
            {alerts.map((alert, index) => {
              return (
                <Alert key={index} severity={alert.severity}>
                  {alert.text}
                </Alert>
              )
            })}
          </div>
          {is_file_loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : null}

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                set_open(false)
              }}
            >
              Close
            </Button>
            <LockedButton text="Upload Feed" onClick={upload_file} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default Versions
